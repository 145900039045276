* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #000;
}

p {
  line-height: 1.9;
}

ul {
  list-style: none;
}

li {
  line-height: 2.2;
}

h1,
h2,
h3 {
  font-weight: 600;
  margin-bottom: 10px;
}

.container {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
}
.col {
  margin: 0 auto !important;
  padding: 0px !important;
  text-align: center !important;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #e6e6e6;
}

.header ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0px;
  padding-left: 0px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.header ul li {
  margin-left: 20px;
}

.header ul li a {
  display: flex;
  align-items: center;
}

.header ul li a:hover {
  color: #777;
}

.header ul li a svg {
  margin-right: 5px;
}

.heading {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 50px;
  padding: 0 20px;
}

.heading p {
  color: #828282;
}

.goals {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.goal {
  background-color: #f4f4f4;
  margin: 10px 0;
  padding: 20px 0 10px;
  position: relative;
}

.goal .close {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  border: none;
  background: none;
}
.th {
  position: sticky;
  top: 0;
}

.form,
.content {
  width: 70% !important;
  margin: 0 auto !important;
}
.table-responsive-sm {
  overflow-x: visible !important;
}
.form-group {
  margin-bottom: 10px !important;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100% !important;
  padding: 10px !important;
  border: 1px solid #e6e6e6 !important;
  border-radius: 5px !important;
  margin-bottom: 10px !important;
  font-family: inherit;
}

.form-group label {
  text-align: left !important;
  display: block !important;
  margin: 0 0 5px 3px !important;
}

.table-icon:hover {
  cursor: pointer;
}

.btn {
  padding: 10px 20px !important;
  border: 1px solid #000 !important;
  border-radius: 5px !important;
  /* background: #000 !important; */
  /* color: #fff !important; */
  font-size: 16px !important;
  font-weight: 700 !important;
  cursor: pointer !important;
  text-align: center !important;
  appearance: button !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.btn-center {
  height: 90px;
}

.btn-text {
  margin-left: 8px !important;
}

.btn-reverse {
  background: #fff !important;
  color: #000 !important;
}

.btn-block {
  width: 100% !important;
  margin-bottom: 20px !important;
}

.btn:hover {
  transform: scale(0.98) !important;
}
.btn:disabled {
  border: 1px solid rgb(160, 160, 160) !important;
}

.loadingSpinnerContainer {
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  /* background-color: rgba(0, 0, 0, 0.5) !important; */
  /* z-index: 5000 !important; */
  display: flex !important;
  justify-content: center !important;
  vertical-align: middle !important;
  align-items: center !important;
}

.loadingSpinner {
  width: 64px !important;
  height: 64px !important;
  border: 8px solid !important;
  border-color: var(--grey-orange-color) transparent var(--white-orange-color)
    transparent !important;
  border-radius: 50% !important;
  animation: spin 1.2s linear infinite !important;
}

.rs-steps-item-icon-wrapper {
  border: 2px solid !important;
}
.rs-steps-item-status-finish .rs-steps-item-icon-wrapper {
  color: var(--dark-orange-color) !important;
  border-color: green !important;
}
.rs-steps-item-status-process .rs-steps-item-icon-wrapper {
  color: var(--dark-orange-color) !important;
  background-color: var(--dark-orange-color) !important;
  border-color: var(--dark-orange-color) !important;
}
.rs-steps-item-status-wait .rs-steps-item-icon-wrapper {
  background-color: transparent !important;
  border-color: var(--dark-blue-color) !important;
}
.rs-steps-item-icon {
  color: var(--dark-blue-color) !important;
}
.rs-steps-item-status-finish {
  color: green !important;
}
.rs-steps-item-status-finish .rs-steps-item-tail {
  border-color: green !important;
}
.rs-steps-item-icon-finish {
  color: green !important;
}
.rs-steps-item-tail {
  color: green !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1280px) {
  .form,
  .content {
    width: 95% !important;
    margin: 0 auto !important;
  }
}
@media (max-width: 945px) {
  .logo {
    height: 2rem !important;
  }
}
@media (max-width: 815px) {
  .header ul li {
    margin-left: 5px;
  }
}
@media (max-width: 768px) {
  .form {
    width: 90%;
  }

  .heading h1 {
    font-size: 2rem;
  }

  .heading p {
    font-size: 1.5rem;
  }
}

@media (max-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px !important;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 1140px !important;
  }
}
/* @media (min-width: 576px){
.container, .container-lg, .container-md, .container-sm {
    max-width: 100% !important;
  }
} */

@font-face {
  font-family: 'Montserrat';
  /* src: url('../public/webfonts/Montserrat-Light.eot'); */
  src: url('./Montserrat-Light.svg#Montserrat-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
* {
  margin: 0;
  padding: 0;
}

:root {
  --white-orange-color: #ffdbd0;
  --grey-orange-color: #f78e6b;
  --dark-orange-color: #f55b29;
  --light-orange-color: #ed6a45;
  --light-blue-color: #0c003d; /*#202C59;*/
  --dark-blue-color: #070024;
  --white: #faf0e6;
}

.dark-blue {
  background-color: var(--dark-blue-color) !important;
}
.dark-orange {
  color: var(--dark-orange-color) !important;
}
.light-orange {
  color: var(--light-orange-color) !important;
}

.settingsButton {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.logo {
  height: 3rem;
  /* background-color: var(--dark-blue-color) !important; */
  /* stroke: var(--dark-orange-color) !important; */
  fill: var(--dark-orange-color);
  align-items: center;
  text-align: center;
  padding-top: 3px;
  padding-bottom: 5px;
}

body {
  height: 100%;
  font-family: 'Montserrat' !important;
  /* overflow-y: scroll; */
  /* margin: 0; */
  /* scroll:"no"; */
  /* overflow-x: hidden; */
}
html {
  height: 100%;
  /* overflow-y: scroll; */
  /* overflow-x: hidden;  */
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 15px;

  /* box-shadow: 0px 5px 10px rgba(20, 30, 54, 0.8); */
}
/* this will do the trick */
::-webkit-scrollbar-track-piece {
  margin-bottom: 72px;
  margin-top: 2px;
}

.parent {
  position: absolute;
  top: 0;
  left: 0;
  /* height: 100%; */
  width: 100%;
  overflow-y: scroll;
}

.others {
  display: flex;
  justify-content: center;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 67px;
  width: 100%;
  background-color: green;
  text-align: center;
  vertical-align: middle;
  justify-content: center;
}

.content {
  padding-bottom: 71px;
  padding-top: 10px;
  height: 100%;
  /* overflow: hidden; */
  width: 100%;
  /* margin: 0; */
}
.menupage {
  vertical-align: middle;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.appbar {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 67px;
  width: 100%;
  background-color: var(--light-blue-color);
  text-align: center;
  vertical-align: middle;
  justify-content: center;
}
.menuicon {
  padding-top: 14px !important;
  padding-bottom: 28px !important;
  color: grey;
  text-align: center;
  vertical-align: middle;
  align-items: center;
}
